import React from 'react';
import Art from '../../assets/images/illustration.svg';

const Illustration = () => {
  return (
      <div className="w-full h-full p-2 flex items-center">
          <img src={Art} alt="w-full h-full"/>
      </div>
  );
};

export default Illustration;
