import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBanner from 'components/HeroBanner';

import {FaGithub, FaLinkedin} from 'react-icons/fa'
import ContactButton from './../components/ui/ContactButton';


const IndexPage: React.FC = () => {
  return (
    <Layout>

        <SEO title="Home" />
        <HeroBanner/>
        
        <div className="w-full h-full flex items-center justify-center p-2 space-x-2 mt-auto justify-self-end">
              <ContactButton link={"https://github.com/yxor"} ContactIcon={FaGithub} />
              <ContactButton link={"https://www.linkedin.com/in/ahmed-tounsi-78293518a/"} ContactIcon={FaLinkedin} />
        </div>
    </Layout>
  );
};

export default IndexPage;
