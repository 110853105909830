import React from 'react'

const ContactButton = ({ContactIcon, link}) => {
    return (
        <a className="w-8 h-8 flex items-center justify-center text-gray-800" href={link} target="_blank" rel="noreferrer">
            <ContactIcon className="w-full h-full">
            </ContactIcon>
        </a>
    )
 
}


export default ContactButton