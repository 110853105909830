import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';
import TitleSection from 'components/ui/TitleSection';
import Illustration from 'components/Illustration';

import * as Styled from './styles';

const Banner = ({ title, subtitle, content, linkTo, linkText }) => (
  <Styled.Banner>      
    <Container section>
      <div className="w-full flex flex-col md:flex-row justify-center md:justify-evenly items-center h-full">
      <div className="w-full md:w-1/2 pl-5">
        <TitleSection title={title} subtitle={subtitle} />
        <Styled.Content>{content}</Styled.Content>
        <Link to={linkTo}>
          <Button primary>{linkText}</Button>
        </Link>
      </div>
      <div className="w-full md:w-1/2 max-w-md p-5">
        <Illustration />
      </div>
      </div>
    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
